import http from '../utils/http'
//
/**
 *  @parms resquest Request address for example:http://197.82.15.15:8088/request/...
 *  @param '/testIp' represents the proxy configured in config, index.js in vue-cil
 */
let resquest = "/prod-api"

// request
export default {
  getListAPI(params) {
    return http.get(`${resquest}/getList.json`, params)
  },
  postFormAPI(params) {
    return http.post(`${resquest}/postForm.json`, params)
  },
  Login(data) {
    return http.post("/prod-api/webLogin", data)
  },
  // role -----------------------------------
  getRoleList() {
    return http.get("/prod-api/providerPortalRole/getRoleList", null)
  },
  // user -----------------------------------
  registerUser(data) {
    return http.post("/prod-api/providerPortalUser/registerUser", data)
  },
  sendEmailVerificationCode(data) {
    return http.get("/prod-api/providerPortalUser/sendEmailVerificationCode?email=" + data)
  },
  checkVerificationCode(data) {
    return http.post("/prod-api/providerPortalUser/checkVerificationCode", data)
  },
  updateUser(data) {
    return http.post("/prod-api/providerPortalUser/updateUser", data)
  },
  forgetPassword(data) {
    return http.post("/prod-api/providerPortalUser/forgetPassword", data)
  },
  changePassword(data) {
    return http.post("/prod-api/providerPortalUser/changePassword", data)
  },
  getUserList(data) {
    return http.post("/prod-api/providerPortalUser/getUserList", data)
  },
  // auth -----------------------------------
  insertAuth(data) {
    return http.post("/prod-api/providerPortalAuth/insertAuth", data)
  },
  selectAuthList(data) {
    return http.post("/prod-api/providerPortalAuth/selectAuthList", data)
  },
  selectAuthById(data) {
    return http.get("/prod-api/providerPortalAuth/selectAuthById?uuid=" + data)
  },
  updateAuth(data) {
    return http.post("/prod-api/providerPortalAuth/updateAuth", data)
  },
  // message --------------------------------
  selectMessage(id) {
    return http.post("/prod-api/providerPortalMessage/selectMessageList/" + id)
  },
  // other ----------------------------------
  selectBundleMealList(data) {
    return http.post("/prod-api/bundleMeal/selectBundleMealList", data)
  },
  selectChronicDiseaseList() {
    return http.get("/prod-api/chronicDisease/selectChronicDiseaseList", null)
  },
  selectDietaryOptionAdditionalList() {
    return http.get("/prod-api/dietaryOptionAdditional/selectDietaryOptionAdditionalList", null)
  },
  selectDietaryOptionList() {
    return http.get("/prod-api/dietaryOption/selectDietaryOptionList", null)
  },
  getUserInfoByNumber(data) {
    return http.get("/prod-api/providerPortalUser/getUserInfoByNumber?numbervalue=" + data)
  },
  checkUserInfoByNumber(data) {
    return http.post("/prod-api/providerPortalUser/checkUserInfoByNumber", data)
  },
  qrcode(uuidvalue, uid) {
    return http.get("/prod-api/providerPortalUser/qrcode?urlvalue=" + uuidvalue + "&uid=" + uid)
  },
  getCode(data) {
    return http.post("/prod-api/providerPortalUser/qrcode", data)
  },
  /*deleteItem(data){
    return http.get("/prod-api/providerPortalUser/deleteItem?uuidvalue="+data)
  }*/
  deleteItem(data) {
    return http.get("/prod-api/providerPortalUser/deleteItem?uuidvalue=" + data)
  },
  selMsgUnread(uid, mid) {
    return http.get("/prod-api/providerPortalUser/selMsgUnread?uid=" + uid + "&mid=" + mid)
  },

  //providerPortalUser/selproIdInfo
  selproIdInfo(proId) {
    return http.get("/prod-api/providerPortalUser/selproIdInfo?proId=" + proId)
  },
  // providerPortalUser/profEdit/4
  profEdit(useID, data) {
    return http.post("/prod-api/providerPortalUser/profEdit/" + useID, data)
  },
  LeadEditProf(useID, data) {
    return http.post("/prod-api/providerPortalUser/LeadEditProf/" + useID, data)
  },
  selproIdList(proId, page, size) {
    return http.get(`/prod-api/providerPortalUser/selproIdList?proId=${proId}&page=${page}&size=${size}`)
  },
  selledIdInfo(ledId) {
    return http.get("/prod-api/providerPortalUser/selledIdInfo?ledId=" + ledId)
  },
  getProIdvalByuserId(userId) {
    return http.get("/prod-api/providerPortalUser/getProIdvalByuserId?userId=" + userId)
  },
  getUserById(userId) {
    return http.get("/prod-api/providerPortalUser/getUserById?userId=" + userId)
  },
  getledInsCoList(ledzip) {
    return http.get("/prod-api/providerPortalUser/getledInsCoList?ledzip=" + ledzip, {timeout: 25000})
  },
  getledInsPlanNumList(ledzip, parId) {
    return http.get("/prod-api/providerPortalUser/getledInsPlanNumList?ledzip=" + ledzip + "&parId=" + parId)
  },
  ///providerPortalAuth/getFimDiagCodeTCountByPage?codeName=&pageNo=0&pageSize=15

  getFimDiagCodeTCountByPage(codeName, pageNo, pageSize) {
    return http.get("/prod-api/providerPortalAuth/getFimDiagCodeTCountByPage?codeName=" + codeName + "&pageNo=" + pageNo + "&pageSize=" + pageSize)
  },
  //getFimDiagCodeTCountByBatch?ids="a0,a02,a030"
  getFimDiagCodeTCountByBatch(ids) {
    return http.get("/prod-api/providerPortalAuth/getFimDiagCodeTCountByBatch?ids=" + ids)
  },
  //
  downloadQrCodePdf(id) {
    return http.get("/prod-api/providerPortalUser/downloadQrCodePdf?uid=" + id)
  },
  getProDetailsByProId(id) {
    return http.get("/prod-api/providerPortalUser/getProDetailsByProId?proId=" + id)
  },
  getMenu(id) {
    return http.get("/prod-api/providerPortalUser/join/" + id)
  },
  getBnpNameList() {
    return http.post("/prod-api/providerPortalAuth/getBnpNameList");
  },
  updateSpAuthEdit(data) {
    return http.post("/prod-api/providerPortalAuth/updateSpAuthEdit", data);
  },
  saveForm(data) {
    return http.post("/prod-api/providerPortalAuth/saveForm", data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  judgeEmailAndUsername(judgeString,proId){
    return http.get("/prod-api/providerPortalUser/judgeEmailAndUsername?judgeString=" + judgeString+"&proId=" + proId);
  },
  getaDitIdList() {
    return http.post("/prod-api/providerPortalAuth/getaDitIdList");
  },

  getSpIngredients(ingName) {
    return http.get("/prod-api/providerPortalAuth/getSpIngredients?ingName=" + ingName);
  },
  getSpDietMods(query) {
    return http.get("/prod-api/providerPortalAuth/getSpDietMods?ingName=" + query);
  },
  getSpDietTypes(proId, query) {
    return http.get("/prod-api/providerPortalAuth/getSpDietTypes?proId=" + proId + "&ingName=" + query);
  },
  getSpBenPrograms(id) {
    return http.get("/prod-api/providerPortalAuth/getSpBenPrograms?id=" + id);
  },
  getSpBenProgramsByProKey(proKey) {
    return http.get("/prod-api/providerPortalUser/getSpBenProgramsByProKey?proKey=" + proKey);
  },
  getSpProView(proKey) {
    return http.get("/prod-api/providerPortalUser/getSpProView?proKey=" + proKey);
  },
  getSPMealSelect() {
    return http.get("/prod-api/providerPortalUser/getSPMealSelect");
  },
  getByPageAndSort(pageNumber, pageSize, proId) {
    return http.get("/prod-api/providerPortalUser/getByPageAndSort?pageNumber=" + pageNumber + "&pageSize=" + pageSize + "&proId=" + proId);
  },
  getByPageAndSortProId(proId, authType, state, authBy, cadence, pageSize, pageNumber) {
    return http.get("/prod-api/providerPortalUser/getByPageAndSort?proId=" + proId + "&authType=" + authType + "&state=" + state + "&authBy=" + authBy + "&cadence=" + cadence + "&pageSize=" + pageSize + "&pageNumber=" + pageNumber);
  },
  getFimDiagCodeNotPage(diagName) {
    return http.get("/prod-api/providerPortalAuth/getFimDiagCodeNotPage?diagName=" + diagName);
  },
  saveTemporarily(email){
    return http.get("/prod-api/providerPortalUser/saveTemporarily?email=" + email);
  },
  PCPcusEdit(row){
    return http.post("/prod-api/providerPortalUser/PCPcusEdit", row);
  },
  downloadQrCode(row){
    return http.post("/prod-api/providerPortalUser/downloadQrCode", row);
  },
  updateFromUser(proUser) {
    return http.post("/prod-api/providerPortalUser/updateFromUser", proUser);
  },
  getSPProViewByPhoneAnd(proKey) {
    return http.get("/prod-api/providerPortalUser/getSPProViewByPhoneAnd?proKey=" + proKey);
  },
  sendCodeInfoForRegister(params) {
    return http.post("/prod-api/providerPortalUser/sendCodeInfoForRegister", params);
  },
  register(params) {
    return http.post("/prod-api/providerPortalUser/pkRegister", params);
  },
  contrastCode(params) {
    return http.post("/prod-api/providerPortalUser/contrastCode", params);
  },
  getAllMenu() {
    return http.get("/prod-api/providerPortalUser/getMenu")
  },
  loginProduct(data) {
    return http.post("/prod-api/providerPortalUser/loginProduct", data)
  },
  getProViewByProId(id) {
    return http.get("/prod-api/providerPortalUser/getProViewByProId?proId=" + id)
  },
  getProViewByProIdFormMenu(id) {
    return http.get("/prod-api/providerPortalUser/getProViewByProIdFormMenu?proId=" + id)
  },
  resetPassword(email) {
    return http.get("/prod-api/providerPortalUser/resetPassword?email=" + email)
  },
  sendUserName(email) {
    return http.get("/prod-api/providerPortalUser/sendUserName?email=" + email)
  },
  updatePass(data) {
    return http.post("/prod-api/providerPortalUser/updatePass", data)
  },
  getByPageSelect(parId) {
    return http.get("/prod-api/providerPortalUser/getByPageSelect?proId=" + parId)
  },
  getForgetPasswordAndEmail(data) {
    return http.post("/prod-api/providerPortalUser/forgetPasswordAndEmail", data)
  },
  selectCompany() {
    return http.get("/prod-api/providerPortalUser/selectCompany", {timeout: 25000})
  },
  insertLeadApi(data) {
    return http.post("/prod-api/providerPortalUser/insertLeadApi", data)
  },
  getLedInsByPlan(ledzip) {
    return http.get("/prod-api/providerPortalUser/getLedInsByPlan?ledzip=" + ledzip, {timeout: 25000})
  },
  getProPatients(data) {
    return http.post("/prod-api/providerPortalUser/getProPatients", data)
  },
  PCPcusEditByType(data) {
    return http.post("/prod-api/providerPortalUser/PCPcusEditByType", data)
  },
  getLedInsCoNewParameter(data){
    return http.get("/prod-api/providerPortalUser/getLedInsCoNewParameter?ledzip="+data.aZip + "&aPlanType="+data.aPlanType  )
  },
  getLedInsCoNewParameterFalse(data){
    return http.get("/prod-api/providerPortalUser/getLedInsCoNewParameterFalse?ledZip="+data.aZip + "&aPlanType="+data.aPlanType + "&parId="+data.parId )
  },
  getAfterHealthInsurerRuns(data){
    return http.get("/prod-api/providerPortalUser/getAfterHealthInsurerRuns?ledZip="+data.aZip + "&aPlanType="+data.aPlanType + "&disId="+data.disId +"&parId="+data.parId )
  },
  getSelRoleNm(data){
    return http.get("/prod-api/providerPortalUser/selRoleNm")
  },
  getSelStatus(data){
    return http.get("/prod-api/providerPortalUser/selStatus")
  },
  getSelRefSource(data){
    return http.get("/prod-api/providerPortalUser/selRefSource")
  },
  uploadLogo(formData) {
    return http.post2("/prod-api/providerPortalUser/uploadLogo", formData);
  },
  uploadTemplate(formData,proId) {
    return http.post2("/prod-api/providerPortalUser/uploadTemplate?proId="+proId, formData);
  },
  getPersonalInformationForDocs(proId) {
    return http.get("/prod-api/providerPortalUser/getPersonalInformationForDocs?pProId="+proId);
  },
}


// import api from '@/api/api'
// methods: {
//   getList() {
//     api.getListAPI(data).then(res => {
//       //data processing
//     }).catch(err => console.log(err))
//   }
// }
